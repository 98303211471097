import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const AboutView = () =>
  import(/* webpackChunkName: "AboutView"*/ "../views/AboutView.vue");
const WebsiteView = () =>
  import(/* webpackChunkName: "WebsiteView"*/ "../views/WebsiteView.vue");
const LogoView = () =>
  import(/* webpackChunkName: "LogoView"*/ "../views/LogoView.vue");
const ContentWritingView = () =>
  import(
    /* webpackChunkName: "ContentWritingView"*/ "../views/ContentWritingView.vue"
  );
const EcommerceView = () =>
  import(/* webpackChunkName: "EcommerceView"*/ "../views/EcommerceView.vue");
const CompanyView = () =>
  import(/* webpackChunkName: "CompanyView"*/ "../views/CompanyView.vue");
const TeamView = () =>
  import(/* webpackChunkName: "TeamVue"*/ "../views/TeamView.vue");
const PortfolioView = () =>
  import(/* webpackChunkName: "PortfolioView"*/ "../views/PortfolioView.vue");
const ContactView = () =>
  import(/* webpackChunkName: "ContactView"*/ "../views/ContactView.vue");
const QuoteView = () =>
  import(/* webpackChunkName: "QuoteView"*/ "../views/QuoteView.vue");
const FaqsView = () =>
  import(/* webpackChunkName: "FaqsView"*/ "../views/FaqsView.vue");

// const BlogView = () =>
//   import(/* webpackChunkName: "BlogView"*/ "../views/BlogView.vue");

// const BlogTemplate1 = () =>
//   import(
//     /* webpackChunkName: "BlogTemplate1"*/ "../components/Blog/templates/BlogTemplate1.vue"
//   );

const PrivacyPolicyView = () =>
  import(
    /* webpackChunkName: "PrivacyPolicyView"*/ "../views/PrivacyPolicyView.vue"
  );
const TermsView = () =>
  import(/* webpackChunkName: "TermsView"*/ "../views/TermsView.vue");
const NotFoundView = () =>
  import(/* webpackChunkName: "NotFoundView"*/ "../views/NotFoundView.vue");

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/about",
    name: "AboutView",
    component: AboutView,
  },
  {
    path: "/website-design",
    name: "WebsiteView",
    component: WebsiteView,
  },
  {
    path: "/e-commerce",
    name: "EcommerceView",
    component: EcommerceView,
  },
  {
    path: "/logo-design",
    name: "LogoView",
    component: LogoView,
  },
  {
    path: "/content-writing",
    name: "ContentWritingView",
    component: ContentWritingView,
  },
  {
    path: "/our-company",
    name: "CompanyView",
    component: CompanyView,
  },
  {
    path: "/our-team",
    name: "TeamView",
    component: TeamView,
  },
  {
    path: "/portfolio",
    name: "PortfolioView",
    component: PortfolioView,
  },
  {
    path: "/contact",
    name: "ContactView",
    component: ContactView,
  },
  {
    path: "/quote",
    name: "QuoteView",
    component: QuoteView,
  },
  {
    path: "/faqs",
    name: "FaqsView",
    component: FaqsView,
  },
  // {
  //   path: "/blogs",
  //   name: "BlogView",
  //   component: BlogView,
  // },
  // {
  //   path: "/blog-test",
  //   name: "BlogTemplate1",
  //   component: BlogTemplate1,
  // },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyView",
    component: PrivacyPolicyView,
  },
  {
    path: "/terms-and-conditions",
    name: "TermsView",
    component: TermsView,
  },
  {
    path: "/:catchAll(.*)", // This is the catch-all route
    name: "NotFoundView",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top
    return { top: 0 };
  },
});

export default router;
