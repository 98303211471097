<template>
  <div class="faqs-section">
    <div class="container-fluid container-lg">
      <div class="row text-start">
        <div class="col-12 col-lg-5 mb-4">
          <h1>Frequently asked questions.</h1>
          <div class="accordion" id="accordionExample">
            <div
              class="accordion-item"
              v-for="(question, index) in faq"
              :key="index"
            >
              <h2 class="accordion-header" :id="'heading-' + index">
                <button
                  class="accordion-button"
                  type="button"
                  :class="{ collapsed: activeIndex !== index }"
                  @click="toggleAccordion(index)"
                  :aria-expanded="activeIndex === index ? 'true' : 'false'"
                  :aria-controls="'collapse-' + index"
                >
                  {{ question.question }}
                </button>
              </h2>
              <div
                :id="'collapse-' + index"
                class="accordion-collapse collapse"
                :class="{ show: activeIndex === index }"
                :aria-labelledby="'heading-' + index"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" v-html="question.answer"></div>
              </div>
            </div>
          </div>
          <router-link class="button quote-btn" to="/faqs">
            Read more ->
          </router-link>
        </div>
        <div class="col-1 d-none d-lg-block"></div>
        <div class="col-12 col-lg-6">
          <h1>Some of our finest work.</h1>
          <p>
            Our team has a successful track record of helping brands scale
            profitably based on high-performing strategies.
          </p>

          <div class="row stats-row">
            <div class="col-6 col-lg-6 stat stat1">
              <h2>500+</h2>
              <p>Completed Projects</p>
            </div>
            <div class="col-6 col-lg-6 stat stat2">
              <h2>90%</h2>
              <p>Client Retention Rate</p>
            </div>
            <div class="col-6 col-lg-6 stat stat3">
              <h2>150%</h2>
              <p>Average ROI</p>
            </div>
            <div class="col-6 col-lg-6 stat stat4">
              <h2>60+</h2>
              <p>Digital Products</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqsSection",
  data() {
    return {
      faq: [
        {
          question: "How much time do you need to complete my website?",
          answer:
            "We usually take 15 to 45 days to complete a website, but this depends on the project size and pace set by the client. Your content delivery and feedbacks – all this affects the speed of completion.",
        },
        {
          question: "Do you offer ongoing support for our website?",
          answer:
            "Yes, we love working with you, so we offer ongoing support for websites that require constant changes.<br /><br /> You can email us at <a href='mailto:contact@devolysis.com'>contact@devolysis.com</a> with your questions and queries.",
        },
        {
          question: "Will my website be mobile-friendly?",
          answer:
            "Yes! It is very important for us that your website runs perfectly in all modern devices and browsers.",
        },
        {
          question: "Do you only create WordPress websites?",
          answer:
            "No, we work on Shopify, Wix, Squarespace and Weebly as well.<br /> We also work on custom websites in the following frameworks:<br /> Frontend: Vue, React, Angular <br />Backend: Django, Laravel, Node <br />Design: Any preferred framework by you. <br />Database: Any preferred Database Management System by you.",
        },
      ],
      activeIndex: null, // To keep track of the active accordion item
    };
  },
  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
.faqs-section h1 {
  font-size: 2.2rem;
  font-weight: 700;
}

.faqs-section p {
  margin-top: 20px;
  font-size: 1rem;
  font-weight: 400;
}

.faqs-section h2 {
  font-size: 2.2rem;
  font-weight: 700;
}

.accordion-item {
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #69696e;
}

.accordion-button {
  background-color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 20px;
}

.accordion-body {
  font-size: 1rem;
  font-weight: 400;
}

.quote-btn {
  display: inline-block;
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 10px 50px;
  border: none;
  color: var(--text-color);
  background-color: var(--primary-color);
  text-decoration: none;
  margin-top: 20px;
  text-shadow: 0.1px 0.1px 0.1px #000000;
}

.quote-btn:hover {
  transform: scale(0.85);
  background-color: #224486;
}

.stats-row .stat {
  width: 40%;
  height: 120px;
  margin: 10px 10px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat1 {
  background-color: var(--primary-color);
}

.stat2 {
  background-color: black;
}

.stat3 {
  background-color: #59574f;
}

.stat4 {
  background-color: var(--secondary-color);
}

.stats-row h2,
.stats-row p {
  color: white;
  text-align: center;
  margin: 0;
  text-shadow: 0.1px 0.1px 0.1px #000000;
}

@media (max-width: 991px) {
  .faqs-section {
    padding: 20px;
  }

  .stats-row .stat {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 575px) {
  .stats-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stats-row .stat {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
