<template>
  <div class="case-studies">
    <div class="container-fluid container-lg">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h2>Read some amazing case studies.</h2>
        </div>
        <div class="col-12 col-lg-6">
          <p>
            We help empower all touchpoints across your brand, helping you
            connect with your customers and audience in authentic and
            transformative ways.
          </p>
        </div>
      </div>
      <div class="row case-images-row">
        <div
          class="col-12 col-lg-6 col-xl-4 case-images"
          v-for="(image, index) in cases"
          :key="index"
        >
          <a :href="image.link">
            <img
              class="img-fluid"
              :id="index"
              :src="image.src"
              :alt="image.alt"
            />
          </a>
        </div>
      </div>
      <div class="row btn-row">
        <div class="col-12 text-center">
          <router-link class="button quote-btn" to="/portfolio"
            >View More Work -></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudies",
  data() {
    return {
      cases: [
        {
          src: require("../assets/images/casestudy/case-img1.webp"),
          alt: "case",
          link: "https://homesbysteven.ca/",
        },
        {
          src: require("../assets/images/casestudy/case-img2.webp"),
          alt: "case",
          link: "https://www.boredapeyachtclub.com/",
        },
        {
          src: require("../assets/images/casestudy/case-img3.webp"),
          alt: "case",
          link: "https://aaassociates.biz/",
        },
        {
          src: require("../assets/images/casestudy/case-img4.webp"),
          alt: "case",
          link: "https://theflashbar.net",
        },
        {
          src: require("../assets/images/casestudy/case-img5.webp"),
          alt: "case",
          link: "https://647media.com/",
        },
        {
          src: require("../assets/images/casestudy/case-img6.webp"),
          alt: "case",
          link: "https://fiume.io/",
        },
        {
          src: require("../assets/images/casestudy/case-img7.webp"),
          alt: "case",
          link: "https://limnia.com/",
        },
        {
          src: require("../assets/images/casestudy/case-img8.webp"),
          alt: "case",
          link: "https://premierwritinggroup.com/",
        },
        {
          src: require("../assets/images/casestudy/case-img9.webp"),
          alt: "case",
          link: "https://ipublicaccountants.com.au/",
        },
      ],
    };
  },
};
</script>

<style scoped>
.case-studies {
  text-align: left;
}

.case-studies h2 {
  font-size: 40px;
  font-weight: 700;
}

.case-studies p {
  font-size: 22px;
  font-weight: 400;
}

.case-images {
  transition: transform 0.4s ease-in-out;
  margin-top: 20px;
}
.case-images img {
  border-radius: 10px;
}

.case-images:hover {
  transform: scale(0.85);
}

.quote-btn {
  display: inline-block; /* Ensure it's treated like an inline element */
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 10px 50px;
  border: none;
  color: var(--text-color);
  background-color: var(--primary-color);
  text-decoration: none;
  text-shadow: 0.1px 0.1px 0.1px #000000;
}

.quote-btn:hover {
  transform: scale(0.85);
  background-color: #224486;
}

.case-images-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.btn-row {
  margin-top: 50px;
}

@media only screen and (max-width: 991px) {
  .case-studies {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .case-studies {
    padding-top: 50px;
  }

  .case-studies h2 {
    font-size: 30px;
  }

  .case-studies p {
    font-size: 16px;
  }
}
</style>
