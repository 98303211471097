<template>
  <div class="customer-reviews">
    <div class="container">
      <header class="text-center mb-5 header-container">
        <img :src="Happy1" alt="Happy Customer" class="happy-icon" />
        <h2 class="title">Happy Customers</h2>
        <img :src="Happy2" alt="Happy Customer" class="happy-icon" />
      </header>

      <swiper
        :slides-per-view="slidesPerView"
        :space-between="30"
        pagination
        grab-cursor
        class="mySwiper"
      >
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="review-card">
            <img :src="testimonial.logo" alt="" class="client-logo" />
            <h3>{{ testimonial.client }}</h3>
            <p>{{ testimonial.feedback }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Happy1 from "@/assets/svgs/happy1.svg";
import Happy2 from "@/assets/svgs/happy2.svg";

export default {
  name: "CustomerReviews",
  data() {
    return {
      Happy1: Happy1,
      Happy2: Happy2,
      testimonials: [
        {
          client: "Muhammad Saeed",
          feedback:
            "Devolysis consistently exceeds expectations, delivering exceptional results on time. Their final website is highly professional.",
          logo: require("../assets/images/logo-carousel/logo-car6.webp"),
        },
        {
          client: "S Amini",
          feedback:
            "They have maintained excellent communication and offered top-notch expertise, earning a perfect 10/10 rating!",
          logo: require("../assets/images/logo-carousel/logo-car5.webp"),
        },
        {
          client: "Ara Khan",
          feedback:
            "Devolysis led by Abdullah provide courteous, competent, and readily available services. Highly Recommended.",
          logo: require("../assets/images/logo-carousel/logo-car1.webp"),
        },
        {
          client: "George Trakl",
          feedback:
            "Devolysis did an excellent job on our landing page and gave us exactly what we needed in a very timely manner. Made good revisions when needed and were patient with us. Excellent professional. 10/10.",
          logo: require("../assets/images/logo-carousel/logo-car3.webp"),
        },
        {
          client: "Fergus Lowrey",
          feedback:
            "The developers are highly professional, understanding user needs, and consistently exceeding expectations. Best Experience.",
          logo: require("../assets/images/logo-carousel/logo-car2.webp"),
        },
        {
          client: "Mubashir Nawaz",
          feedback:
            "Developed a fully functional responsive website, surpassing expectations in record time.",
          logo: require("../assets/images/logo-carousel/logo-car8.webp"),
        },
      ],
    };
  },
  computed: {
    slidesPerView() {
      // Adjust the number of slides based on screen width
      return window.innerWidth < 768 ? 1 : window.innerWidth < 992 ? 2 : 3;
    },
  },
};
</script>
<style scoped>
.customer-reviews {
  margin-top: 100px;
  margin-bottom: 80px;
}
.happy-icon {
  width: 80px; /* Adjust size as needed */
  height: auto;
  margin: 0 15px; /* Add some horizontal spacing */
}
.title {
  font-size: 36px;
  font-weight: 700;
  margin: 0 20px; /* Add spacing around the title */
}
.mySwiper {
  padding: 20px 0;
}
.review-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: space-between; /* This will distribute space evenly */
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-height: 300px; /* Set a minimum height for uniformity */
  /* max-height: 350px; */
  overflow: hidden; /* Prevent overflow of text */
}
.client-logo {
  width: 100px;
  height: auto;
  margin: 15px 0; /* Adjust margin to give space above and below */
}
.review-card h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0; /* Adjust margin to give space */
}
.review-card p {
  font-size: 16px;
  font-weight: 400;
  flex-grow: 1; /* This allows the paragraph to grow and fill available space */
  margin: 10px 0; /* Ensure space around text */
}

.header-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

@media only screen and (max-width: 575px) {
  .happy-icon {
    display: none;
  }
}
</style>
