<template>
  <div class="services-section">
    <div class="container-fluid container-lg">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h2 class="heading-txt">
            We Design the Best Websites and Branding for Your Success.
          </h2>
        </div>
        <div class="col-lg-6 text-end removelg-div">
          <img :src="LineSvg" alt="LineSvg" />
        </div>
      </div>
      <div class="row services-row">
        <div class="col-2 removelg-div">
          <span class="num-txt">10+</span>
          <span class="label-txt">Designers & Developers</span>
          <span class="num-txt second-num">4+</span>
          <span class="label-txt">Years in business</span>
        </div>
        <div class="col-12 col-lg-10">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="card mx-2">
                <!-- Add margin to card itself -->
                <div class="card-body">
                  <h3 class="card-title">
                    <font-awesome-icon :icon="['fas', 'window-restore']" />
                  </h3>
                  <h4 class="card-subtitle mb-2">WEBSITE DESIGN</h4>
                  <p class="card-text">
                    We specialize in website design, where we create
                    user-friendly and visually striking websites, uniquely
                    tailored to your specific needs.
                  </p>
                  <router-link to="/website-design" class="card-link"
                    >Website Design</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="card mx-2">
                <!-- Add margin to card itself -->
                <div class="card-body">
                  <h3 class="card-title">
                    <font-awesome-icon :icon="['fas', 'store']" />
                  </h3>
                  <h4 class="card-subtitle mb-2">E-COMMERCE</h4>
                  <p class="card-text">
                    With our e-commerce website design expertise, we'll ensure
                    your store shines on the global stage, making your brand
                    accessible to the world.
                  </p>
                  <router-link to="/e-commerce" class="card-link"
                    >E-commerce Solutions</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row services-row2">
        <div class="col-12 col-lg-2 removelg-div">
          <span class="num-txt">100%</span>
          <span class="label-txt">Satisfaction rate</span>
          <span class="num-txt second-num">500+</span>
          <span class="label-txt">Successful projects</span>
        </div>
        <div class="col-12 col-lg-10">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="card mx-2">
                <!-- Add margin to card itself -->
                <div class="card-body">
                  <h3 class="card-title">
                    <font-awesome-icon :icon="['fas', 'vector-square']" />
                  </h3>
                  <h4 class="card-subtitle mb-2">LOGO DESIGN</h4>
                  <p class="card-text">
                    Your logo is your brand's visual identity. Let us craft a
                    distinctive logo that shines in the world, leaving a lasting
                    impression about your business.
                  </p>
                  <router-link to="/logo-design" class="card-link"
                    >Logo Design</router-link
                  >
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="card mx-2">
                <!-- Add margin to card itself -->
                <div class="card-body">
                  <h3 class="card-title">
                    <font-awesome-icon :icon="['fas', 'pen']" />
                  </h3>
                  <h4 class="card-subtitle mb-2">CONTENT WRITING</h4>
                  <p class="card-text">
                    We specialize in content writing that drives engagement and
                    tells your unique story. Elevate your brand's voice with our
                    expert content services.
                  </p>
                  <router-link to="/content-writing" class="card-link"
                    >Content Writing</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineSvg from "@/assets/svgs/dragline.svg";

export default {
  name: "ServicesSection",
  data() {
    return {
      LineSvg: LineSvg,
    };
  },
};
</script>

<style scoped>
.services-section {
  text-align: left;
}

.heading-txt {
  font-size: 48px;
  font-weight: 600;
}

.services-row,
.services-row2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.services-row2 {
  margin-top: 50px;
}

.num-txt {
  font-size: 48px;
  font-weight: 500;
  display: block;
}

.label-txt {
  font-size: 20px;
  font-weight: 500;
  display: block;
}

.card-body {
  padding: 30px;
}

.card-subtitle {
  font-size: 25px;
  font-weight: 600;
}

.card-title {
  font-size: 25px;
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.card-text {
  font-size: 16px;
  font-weight: 400;
}

.second-num {
  margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
  .heading-txt {
    font-size: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .services-section {
    padding-right: 20px;
    padding-left: 20px;
  }

  .removelg-div {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .heading-txt {
    font-size: 30px;
  }
}
</style>
