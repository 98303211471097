<template>
  <div class="home">
    <MainMenu />
    <div class="home-hero">
      <HomeHero />
    </div>
    <div class="logo-carousel">
      <LogoCarousel />
    </div>
    <div class="service-section">
      <ServicesSection />
    </div>
    <div class="process-section">
      <ProcessSection />
    </div>

    <div class="case-studies">
      <CaseStudies />
    </div>

    <HappyCustomers />

    <div class="faqs-section">
      <FaqsSection />
    </div>

    <div class="call-action">
      <CallAction />
    </div>

    <div class="footer-menu">
      <FooterMenu />
    </div>
  </div>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import HomeHero from "@/components/Home/HomeHero.vue";
import LogoCarousel from "@/components/Home/LogoCarousel.vue";
import ServicesSection from "@/components/Home/ServicesSection.vue";
import ProcessSection from "@/components/Home/ProcessSection.vue";
import CaseStudies from "@/components/CaseStudies.vue";
import HappyCustomers from "@/components/HappyCustomers.vue";
import FaqsSection from "@/components/FaqsSection.vue";
import CallAction from "@/components/CallAction.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "HomeView",
  metaInfo() {
    return {
      title: "Website Services - Home",
      meta: [
        {
          name: "description",
          content:
            "We offer professional website development services to help businesses establish a strong online presence.",
        },
        {
          name: "keywords",
          content:
            "web development, website services, professional web design, online presence, business websites",
        },
      ],
    };
  },
  components: {
    MainMenu,
    HomeHero,
    LogoCarousel,
    ServicesSection,
    ProcessSection,
    CaseStudies,
    HappyCustomers,
    FaqsSection,
    CallAction,
    FooterMenu,
  },
};
</script>

<style scoped>
.home-hero {
  padding-top: 80px;
}

.logo-carousel {
  margin-top: 30px;
}

.service-section {
  margin-top: 100px;
}

.process-section {
  margin-top: 150px;
}

.case-studies {
  margin-top: 150px;
}

.faqs-section {
  margin-top: 150px;
}

.call-action {
  margin-top: 120px;
}

.footer-menu {
  margin-top: 100px;
}
</style>
