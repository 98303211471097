<template>
  <div class="process-section">
    <div class="container-fluid container-lg">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h2 class="heading-txt">It's all about the people, and process.</h2>
        </div>
        <div class="col-3"></div>
        <div class="col-12 col-lg-3">
          <router-link class="button quote-btn" to="/about"
            >About Us -></router-link
          >
        </div>
      </div>
      <div class="row card-row1">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                <font-awesome-icon
                  class="icons"
                  :icon="['fas', 'people-arrows']"
                />
                Discover Together
              </h3>
              <p class="card-text">
                To begin, we want to get to know your business inside and out.
                We’ll explore your strengths, your products or services, and
                your unique selling points. Understanding your vision for how
                you want to present your business to the world is key. We have a
                proven framework in place to streamline this process, ensuring
                that every crucial aspect is covered when it comes to building
                your website.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                <font-awesome-icon
                  class="icons"
                  :icon="['fas', 'boxes-stacked']"
                />Building Blocks
              </h3>
              <p class="card-text">
                We carefully construct the building blocks of your website, from
                layout and design to interactive features and seamless
                navigation. Our goal is to create a user-friendly, responsive,
                and performance-driven website that not only reflects your
                brand’s identity but also meets the expectations of your target
                audience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-row2">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                <font-awesome-icon
                  class="icons"
                  :icon="['fas', 'code']"
                />Development Phase
              </h3>
              <p class="card-text">
                Throughout the development process, we maintain a strong focus
                on quality assurance, ensuring that every component aligns with
                your objectives and performs flawlessly. As we piece together
                the digital puzzle, we keep you in the loop, providing regular
                updates and opportunities for your feedback, ensuring that the
                final product aligns perfectly with your vision.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">
                <font-awesome-icon
                  class="icons"
                  :icon="['fas', 'cloud']"
                />Deployment Phase
              </h3>
              <p class="card-text">
                In the deployment phase, we make your website live for everyone
                to see and use. It’s when your online presence goes live, and we
                ensure it works well on different devices. We test everything
                thoroughly before launching to ensure a smooth experience for
                your visitors. Our goal is to make your website work perfectly
                so that you can succeed online.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessSection",
};
</script>

<style scoped>
.process-section {
  text-align: left;
}

.heading-txt {
  font-size: 40px;
  font-weight: 600;
}

.quote-btn {
  display: inline-block; /* Ensure it's treated like an inline element */
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  padding: 10px 50px;
  border: none;
  color: white;
  background-color: black;
  text-decoration: none;
}

.quote-btn:hover {
  transform: scale(0.85);
  background-color: #224486;
}

.card-row1 {
  margin-top: 50px;
}

.card-row2 {
  margin-top: 20px;
}

.card {
  padding: 10px 20px;
  min-height: 250px;
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.icons {
  font-size: 25px;
  margin-right: 20px;
  color: var(--secondary-color);
}

.card-title {
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .process-section {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .heading-txt {
    font-size: 30px;
  }
  .card-title {
    font-size: 20px;
  }
}
</style>
